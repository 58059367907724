import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import { Layout } from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"

const ThanksPage = ({ data }) => {
    const intl = useIntl()
    const content =
        intl.locale === "ja" ? data.allContentfulContactPage.nodes[0] : data.allContentfulContactPage.nodes[1]
    return (
        <Layout>
            <div css={wrapper}>
                <GatsbyImage
                    image={getImage(content.thankYouPageBackground)}
                    key={`thank you background image`}
                    alt={`thank you background image`}
                    css={backgroundImage}
                />
                <div css={textBox}>
                    <h3>{content.thankYouMessage}</h3>
                </div>
            </div>
        </Layout>
    )
}
export default ThanksPage

const wrapper = css`
    width: 100%;
    height: 60vh;
    margin: 0;
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    background: black;

    @media ${device.sp} {
        height: 40vmax;
    }
`
const backgroundImage = css`
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.4;
`
const textBox = css`
    z-index: 9;
    color: white;
    text-align: center;

    @media ${device.sp} {
        width: 95%;
        h3 {
            font-size: 1.7rem;
        }
    }
`

export const query = graphql`
    query {
        allContentfulContactPage {
            nodes {
                thankYouMessage
                thankYouPageBackground {
                    gatsbyImageData(width: 500)
                }
            }
        }
    }
`
